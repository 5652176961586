import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieImageInteractive from "../../../composants/layout/LayoutGalerieImageInteractive"
import Pannellum from "../../../composants/image/Panellum"

import { obtenirPage } from "../../../js/client-es/utils"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(
      filter: { code: { eq: "observatoireRoqueMuchachos360" } }
    ) {
      ...FragmentPageYaml
    }
  }
`

export default function RoqueMuchachos360(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")

  const composantImageInteractive = (
    <Pannellum
      urlImageSource="/ressources/360/roqueMuchachos.jpg"
      altitudeParDefaut={0}
    />
  )

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          Ce 360° vous plonge au cœur d’un coucher de Soleil à 2400 mètres
          au-dessus de la mer, au sommet de l’observatoire du Roque de los
          Muchachos sur l’île de La Palma aux Canaries. Cet observatoire est
          l’un des plus importants de l’hémisphère nord avec l’observatoire du
          Mauna Kea situé à Hawai.
        </p>
        <div id="galerie-image-interactive-texte-exifs">
          Panoramique de 20 images, rotule panoramique Bushman Gobi, Canon EOS
          6D refiltré Astrodon, Samyang 24 mm F1.4 @ F4, 400 ISO. Poses
          unitaires de 0.25 seconde.
        </div>
      </section>
    </LayoutGalerieImageInteractive>
  )
}
